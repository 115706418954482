<template>
  <VContainer
    class="pa-0 position-sticky--top text-center"
    fluid
  >
    <VRow justify="center">
      <VCol cols="auto">
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-location-add"
          class="v-btn--aside"
          @click="showGoalForm"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-plus
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2 text-nowrap mt-2">
          {{ $t('goals.button_add') }}
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'GoalFormButton',
  methods: {
    showGoalForm() {
      this.$root.$emit('show-goal-form');
    },
  },
};
</script>

<style scoped>

</style>
